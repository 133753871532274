import React, { FC, useContext } from 'react';
import { Box, Heading, ResponsiveContext, Grid, Text } from 'grommet';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ILink, IImage } from '../utils/componentInterfaces';
import Button from './button';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';
import { HeaderPopupContext } from '../context';
import { MaxWidth } from './maxWidth';
import TagBox from './tagBox';
import { Tag } from './tag';
import ActionButtons from './actionButtons';

interface props {
    buttonText: string;
    content: string;
    heading: string;
    intro?: string;
    link: ILink;
    gallery: [IImage];
    position: string;
    style: string;
    tag?: string;
    linkOrPopup: 'popup' | 'link' | 'none' | 'both';
    noWrap?: boolean;
}

const TheBlock: FC<any> = ({
    buttonText,
    content,
    heading,
    intro,
    link,
    gallery,
    position,
    style,
    linkOrPopup,
    tag,
}) => {
    const size = useContext(ResponsiveContext);
    const { onOpen } = useContext(HeaderPopupContext);
    let direction = 'column';

    if (size !== 'small' && position === 'left') {
        direction = 'row';
    }
    if (size !== 'small' && position === 'right') {
        direction = 'row-reverse';
    }
    return (
        <MaxWidth direction={direction} gap="large" relative>
            {tag && <Tag position={position}>{tag}</Tag>}
            {gallery && gallery.length >= 1 && (
                <Box
                    width={size === 'large' ? { max: '514px' } : { max: '100%' }}
                    basis="100%"
                    flex={{ shrink: 0, grow: 1 }}
                >
                    <TagBox>
                        <Grid columns={['1/2', '1/2']} gap="small">
                            {gallery.map((image) => (
                                <Box
                                    border={{
                                        color: 'white',
                                        size: 'medium',
                                        style: 'solid',
                                        side: 'all',
                                    }}
                                    round="xsmall"
                                    overflow="hidden"
                                >
                                    <GatsbyImage
                                        image={image?.localFile?.childImageSharp?.gatsbyImageData}
                                        alt={image.altText}
                                    />
                                </Box>
                            ))}
                        </Grid>
                    </TagBox>
                </Box>
            )}
            <TagBox>
                <Box direction="column">
                    {heading && (
                        <Heading level="2" color="accent-2" margin={{ bottom: 'medium', top: 'none' }}>
                            {heading}
                        </Heading>
                    )}
                    {intro && (
                        <Heading level="3" color="accent-1" margin={{ bottom: 'medium', top: 'none' }}>
                            {intro}
                        </Heading>
                    )}
                    <HtmlContent marginBottom dangerouslySetInnerHTML={createMarkup(content)} />

                    {buttonText && linkOrPopup !== 'popup' && link && (
                        <Button
                            label={<Text color="white">{buttonText}</Text>}
                            to={link.uri}
                            color="brand"
                            primary
                            reverse
                            alignSelf="start"
                        />
                    )}
                    {buttonText && linkOrPopup === 'popup' && (
                        <Button
                            label={<Text color="white">{buttonText}</Text>}
                            color="brand"
                            primary
                            reverse
                            alignSelf="start"
                            onClick={onOpen}
                        />
                    )}
                    {linkOrPopup === 'both' && <ActionButtons />}
                </Box>
            </TagBox>
        </MaxWidth>
    );
};

const GalleryTextBlock: FC<props> = (props) => {
    const { style, noWrap } = props;

    return (
        <>
            {noWrap ? (
                <TheBlock {...props} />
            ) : (
                <Box
                    className={style === 'standard' ? 'light-1' : 'brand'}
                    pad={{ vertical: 'xlarge' }}
                    background={
                        style === 'standard'
                            ? null
                            : {
                                  color: 'brand',
                                  opacity: 0.1,
                                  dark: false,
                              }
                    }
                >
                    <TheBlock {...props} />
                </Box>
            )}
        </>
    );
};

export default GalleryTextBlock;
